export const PARAMS_MAPPING = [
  { long: 'publicToken', short: 'pt' },
  { long: 'stage', short: 'st' },
  {
    long: 'initialValues',
    short: 'iv',
    children: [
      {
        long: 'taxReferenceOrPhone',
        short: 'tr',
        variants: ['taxReference'],
      },
    ],
  },
];
