import { h, render } from 'preact';

import { ROUTES } from '~/config';
import { STAGES } from '~/containers/SelfEmployedPage/constants';
import mergeObjects from '~/utils/mergeObjects';
import { encode } from '~/utils/urlParams';

import * as styles from './styles.css';
import { PARAMS_MAPPING } from './constants';

export { PARAMS_MAPPING };

export default class SelfEmployed {
  static STAGES = STAGES;

  constructor(options = {}) {
    this.options = mergeObjects(options, {
      container: document.getElementById(`${process.env.PREFIX}-self-employed`),
    });
  }

  get frameUrl() {
    const { publicToken, stage, initialValues = {} } = this.options;

    return `${process.env.APP_BASE_URL}${ROUTES.selfEmployed}?${encode(
      {
        publicToken,
        stage,
        initialValues,
      },
      PARAMS_MAPPING,
    )}`;
  }

  isRendered = false;

  render(options = {}) {
    const { container } = mergeObjects(this.options, options);

    container.innerHTML = '';

    render(
      <iframe className={styles.frame} frameBorder={0} src={this.frameUrl} />,
      container,
    );

    this.isRendered = true;
  }

  destroy() {
    if (this.isRendered) {
      const { container } = this.options;

      container.innerHTML = '';

      this.isRendered = false;

      this.onDestroy();
    }
  }
}
